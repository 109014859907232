export const PROFILE_EDIT_URL = '/profile/edit';

type CountryToLocale = {
  [key: string]: string;
};

// Below locale codes are compatible with @shopify/address's
// list of accepted codes.
export const COUNTRY_TO_LOCALE: CountryToLocale = {
  AR: 'ES_AR',
  AU: 'EN_AU',
  AT: 'DE_AT',
  BE: 'NL_BE',
  BG: 'BG_BG',
  CA: 'EN_CA',
  CO: 'ES_CO',
  HR: 'HR_HR',
  CY: 'EL_CY',
  CZ: 'CS_CZ',
  DK: 'DA_DK',
  DO: 'ES_DO',
  EE: 'ET_EE',
  FI: 'FI_FI',
  FR: 'FR_FR',
  DE: 'DE_DE',
  GR: 'EL_GR',
  HU: 'HU_HU',
  IN: 'HI_IN',
  ID: 'ID_ID',
  IE: 'EN_IE',
  IL: 'HE_IL',
  IT: 'IT_IT',
  LV: 'LV_LV',
  LT: 'LT_LT',
  LU: 'LB_LU',
  MT: 'MT_MT',
  MX: 'ES_MX',
  NL: 'NL_NL',
  NZ: 'EN_NZ',
  NO: 'NO_NO',
  PE: 'ES_PE',
  PH: 'EN_PH',
  PL: 'PL_PL',
  PT: 'PT_PT',
  SK: 'SK_SK',
  SI: 'SL_SI',
  ZA: 'EN_ZA',
  ES: 'ES_ES',
  SE: 'SV_SE',
  TW: 'ZH_TW',
  TH: 'TH_TH',
  GB: 'EN_GB',
  UY: 'ES_UY',
  GT: 'ES_GT',
  CL: 'ES_CL'
};
