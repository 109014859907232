export const NATIONAL_ID_COUNTRY_CODES = ['UY', 'DO', 'CO', 'AR', 'GT', 'CL', 'PE']

// To use with stdout for id verification
export const COUNTRY_NATIONAL_ID_TYPES: { [key: string]: string } = {
  CL: 'rut',
  DO: 'cedula',
  GT: 'nit',
  UY: 'cedula',
  PE: 'cedula'
}

export const COUNTRY_NATIONAL_ID_PLACEHOLDERS: { [key: string]: string } = {
  AR: 'Código Único de Identificación Laboral (CUIL)',
  CO: 'Cédula de Ciudadanía (CC)',
  CL: 'Rol Único Tributario',
  DO: 'Cédula de Identidad y Electoral (CIE)',
  GT: 'Número de Identification Tributaria (NIT)',
  UY: 'Cédula de Identidad (CI)',
  PE: 'Código Único de Identificación (CUI)'
}
